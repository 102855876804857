/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from "./Common/global-styles"
import Fonts from "./Common/fonts"
import "./layout.css"
import BuyNow from "./BuyNow"

const Layout = ({ children }) => {
  return (
    <>
        <Fonts/>
        <GlobalStyle/>
        <main>{children}</main>
        <BuyNow />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
