import styled from 'styled-components';
import {device} from './device';

export const Commonbtn = styled.a`
    background: #ff3c1c;
    border: 2px solid #ff3c1c;
    color: #fff;
    padding: 10px 40px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-decoration:none;

    :hover{
        background: #fff;
        color: #ff3c1c;
        text-decoration:none;
    }

    @media ${device.mobileXL} {
        padding: 8px 30px;
    }
`;

export const CommonbtnRound = styled.a`
    background: #ff3c1c;
    border: 2px solid #ff3c1c;
    color: #fff;
    padding: 10px 40px;
    text-decoration: none;
    display: inline-flex;
    align-items:center;
    cursor: pointer;
    text-decoration:none;
    border-radius:25px;

    span{
        display: block;
        line-height: 1.7;
    }

    svg{
        width:17px;
        margin-left:10px;
    }
    :hover{
        background: #fff;
        color: #ff3c1c;
        text-decoration:none;
    }
    :hover svg path{
        fill: #ff3c1c;
    }

    @media ${device.mobileXL} {
        padding: 8px 30px;
    }
`;

export const SectionHeading = styled.h2`
    text-align:left;
    line-height:1;

    @media ${device.tablet} {
        line-height:1;
    }
`;

export const AnchorLink = styled.a`
    color:#111;
    font-family: 'PT Serif', serif;
    font-weight:700;
    font-size:16px;
    line-height:20px;
    text-align:left;

    :hover{
        color:#111;
    }
`;
