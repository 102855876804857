import React from "react";
import { Helmet } from "react-helmet"

const Fonts = () => {
    return(
        <Helmet>
            {/* <link
                href="https://fonts.googleapis.com/css2?family=Amiri:wght@700&family=Open+Sans&display=swap" 
                rel="stylesheet"/> */}
                <link href="https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;600&family=Open+Sans&display=swap" rel="stylesheet" />
            {/* <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap" rel="stylesheet"/>   */}
        </Helmet>
    );
};

export default Fonts;
