import {createGlobalStyle} from 'styled-components';
import {device} from './device';

const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;

        .ReactModal__Overlay{
            z-index:9999 !important;
        }
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Source Serif Pro', serif;
        font-weight: 700;
        margin-bottom:20px;
        color:#282828;
    }
    h1{
        font-size:48px;
        line-height:54px;

        @media ${device.laptop} {
            font-size:42px;
            line-height:48px;
        }

        @media ${device.mobileXL} {
            font-size:38px;
            line-height:44px;
        }
        @media ${device.mobileL} {
            font-size:30px;
            line-height:34px;
        }

    }
    h2{
        font-size: 42px;
        line-height:48px;

        @media ${device.tablet} {
            font-size:40px;
            line-height:46px;
        }

        @media ${device.mobileL} {
            font-size:34px;
            line-height:40px;
        }
    }
    h3{
        font-size: 36px;
        line-height:42px;

        @media ${device.tablet} {
            font-size:34px;
            line-height:40px;
        }

        @media ${device.mobileL} {
            font-size:30px;
            line-height:36px;
        }
    }
    h4{
        font-size:30px;
        line-height:36px;

        @media ${device.tablet} {
            font-size:28px;
            line-height:32px;
        }
    }
    h5{
        font-size:22px;
        line-height:28px;
    }
    h6{
        font-size:18px;
        line-height:24px;

        @media ${device.tablet} {
            text-align:left;
        }

        @media ${device.mobileXL} {
            font-size:17px;
            line-height:23px;
        }
    }

    p{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color:#424242;
        margin-bottom:20px;
        font-size:14px;
        line-height:1.7;
    }
    a{
        font-size:16px;
        font-family: 'Open Sans', sans-serif;
        font-weight:400;
    }
    img{
        margin-bottom:0px;
    }
`;

export default GlobalStyle;
